import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';

// Custom Components
import AttachmentDownload from '../../../General/AttachmentDownload';
import AttachmentContainer from '../../../General/AttachmentContainer';
import ResponseTemplate from './ResponseTemplate';
import NotesDisplay from '../GeneralDisplays/NotesDisplay';

import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Fade } from 'react-reveal';
import EmailTemplate from './EmailTemplate';

axios.defaults.withCredentials = true;

const nothing = 0;
const notesShowing = 1;
const infoShowing = 2;
const emailShowing = 3;
const attachmentsShowing = 4;
const respondShowing = 5;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function EmployeeApplicationEdit(props) {
    const [application, setApplication] = useState(props.application);
    const [width, height] = useWindowSize();

    // States
    const [user, setUser] = useState(null);

    // Edit Nav
    const [showing, setShowing] = useState(infoShowing);

    // Application Response
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");
    const [amount, setAmount] = useState(application.amount);
    const [applicationResponseShowing, setApplicationResponseShowing] = useState(false);


    const [pendingVerification, setPendingVerification] = useState(false);
    const [key, setKey] = useState("");
    const [responseSubmitted, setResponseSubmitted] = useState(false);
    const [responseError, setResponseError] = useState(false);
    const [responseErrorMessage, setResponseErrorMessage] = useState("");

    // Email

    const [email, setEmail] = useState("");
    const [emailSubmitted, setEmailSubmitted] = useState(false);

    // Notes


    // Attachments

    const [attachments, setAttachments] = useState([]);
    const [pendingFile, setPendingFile] = useState(null);
    const [attachmentsSubmitted, setAttachmentsSubmitted] = useState(false);
    const [attachmentError, setAttachmentError] = useState(false);

    // Events

    // Navbar

    const onNavbarClick = (value) => {
        // Reset state values to avoid confusion
        setPendingVerification(false);
        setEmail("");
        setMessage("");
        setKey("");
        if (value === showing) {
            setShowing(nothing);
        } else {
            setShowing(value);
        }
    }

    // Note Events

    const onNotesUpdate = (notes) => {
        application.notes = notes;
    }

    // Application Events

    const onResponseToggle = () => {
        setApplicationResponseShowing(!applicationResponseShowing);
    }

    const onMessageInput = (event) => {
        setMessage(event.target.value);
    }

    const onResponseSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('token', application.token);
        formData.append('status', status);
        formData.append('message', message);
        formData.append('key', key);
        formData.append('amount', amount);
        axios.post('/api/respond-to-application', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setResponseSubmitted(true);
                console.log(res.data.application);
                // Update local application state
                setApplication(res.data.application);
                props.updateApplications(res.data.application);
            } else {
                setResponseError(true);
                setResponseErrorMessage(res.data.message);
            }
        })
    }

    // Email Events

    const onEmailInput = (event) => {
        setEmail(event.target.value);
    }

    const onSubmitEmail = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('message', email);
        formData.append('email', application.email);
        formData.append('token', application.token);
        formData.append('firstName', application.firstName);
        formData.append('key', key);
        axios.post('/api/email-user', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                application.notes = res.data.notes;
                setEmailSubmitted(true);
            }
        })
    }


    // Attachments

    const onFileUpload = (event) => {
        setAttachments(attachments.concat(event.target.files[0]));
        event.target.value = null;
    }

    const onPendingDelete = () => {
        setPendingFile(null);
    }

    const onFileDelete = (file) => {
        const newAttachments = attachments.filter(attachment => attachment !== file);
        setAttachments(newAttachments);
    }

    // Submit Attachments

    const onSubmitAttachments = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('token', application.token);
        for (const attachment of attachments) {
            formData.append('attachments', attachment);
        }

        axios.post('/api/add-application-attachments', formData, {
            withCredentials: true,
        }).then((res) => {
            if (res.data.status) {
                setAttachmentsSubmitted(true);
                application.attachments = res.data.attachments;
            } else {
                setAttachmentError(true);
            }

        })

    }

    // User Events

    const getUserInfo = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('id', application.applicantID);

        axios.post('/api/user-by-id', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setUser(res.data.user);
            } else {

            }

        })
    }

    // Renders

    // Review Render

    const renderApplicationReview = () => {
        return (
            <>
                <div className='container-header'>
                    <h1 className='header'>All Details</h1>
                </div>

                <div className="employee-review-container">
                    <div className="review-section">
                        <h2 className='header'>General</h2>
                        <div className="review-items">
                            <div className="review-item">
                                <h3>First Name: </h3> <p>{application.firstName ? application.firstName : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Last Name: </h3> <p>{application.lastName ? application.lastName : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Email: </h3> <p>{application.email ? application.email : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Birthday: </h3> <p>{application.birthday ? application.birthday : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Phone: </h3> <p>{application.phone ? application.phone : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Income: </h3> <p>{application.income ? application.income : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Gender: </h3> <p>{application.gender ? application.gender : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Relationship Status: </h3> <p>{application.relationshipStatus ? application.relationshipStatus : "N/A"}</p>
                            </div>

                        </div>


                    </div>

                    <div className="review-section">
                        <h2 className='header'>Address Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Street Address: </h3> <p>{application.streetAddress ? application.streetAddress : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Street Address 2: </h3> <p>{application.streetAddress2 ? application.streetAddress2 : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>City: </h3> <p>{application.city ? application.city : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>State: </h3> <p>{application.state ? application.state : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>ZIP: </h3> <p>{application.ZIP ? application.ZIP : "N/A"}</p>
                            </div>
                        </div>

                    </div>

                    <div className="review-section">
                        <h2 className='header'>Medical Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Bleeding Disorder: </h3> <p>{application.bleedingDisorder ? "Yes" : "No"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Relationship to Bleeding Disorder: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderRelationship : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Type: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderType : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Severity: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderSeverity : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>HTC: </h3> <p>{application.bleedingDisorder ? application.HTC : "N/A"}</p>
                            </div>
                        </div>
                    </div>

                    <div className="review-section">
                        <h2 className='header'>Request Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Type of Assistance: </h3> <p>{application.type ? application.type : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Method of Assistance: </h3> <p>{application.method ? application.method : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Amount Requested: </h3> <p>{application.amount ? "$" + application.amount : "N/A"}</p>
                            </div>

                            <div class="review-item">
                                <h3>Amount Paid: </h3> <p>{application.status === "Approved" && application.amount !== "0"
                                    ? "$" + application.amount : "N/A"}</p>
                            </div>
                        </div>

                        <h3>Reason for Applying:</h3>
                        <p className='details-text'>{application.details}</p>
                    </div>

                    <div className="review-section">
                        <h2 className='header'>Attachments</h2>

                        {renderAttachments()}

                    </div>

                    <div className="review-section">
                        <h2 className='header'>Referral Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Referred: </h3> <p>{application.referred ? "Yes" : "No"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Who Referred You?: </h3> <p>{application.referred ? application.referrer : "N/A"}</p>
                            </div>

                        </div>
                    </div>

                    <div className='review-section'>
                        <h2 className='header'>User Information</h2>
                        {renderUserInfo()}
                    </div>

                </div>
            </>
        )
    }

    // Application Renders

    const renderResponseError = () => {
        if (responseError) {
            return (
                <Fade bottom>
                    <h2 className='error-text'>{responseErrorMessage}</h2>
                </Fade>
            )
        }
    }

    const renderKeyConfirm = () => {
        if (pendingVerification) {
            if (!responseSubmitted) {
                return (
                    <>
                        <>
                            <div className='pending-verification'>
                                <h1 className='header'>Verify Information</h1>
                                <h1 className='header'>Email Preview:</h1>
                                <ResponseTemplate status={status} message={message}
                                    token={application.token} firstName={application.firstName} />
                                <div className='response-password-input-container'>
                                    <label>
                                        Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                        <Password type="password" name="key" value={key} onChange={(event) => setKey(event.target.value)} toggleMask={true} />
                                    </label>
                                </div>
                                {renderResponseError()}
                                {renderResponseButton()}
                            </div>
                        </>
                    </>
                )
            } else {
                return (
                    <>
                        <div className='response-submitted'>
                            <h1 className='header'>Response Submitted!</h1>
                            <h2 className='header'>Your response has been submitted to the database and the user has been notified of their decision.</h2>
                        </div>
                    </>
                )
            }
        }
    }

    const renderResponseButton = () => {
        // Main Response
        if (!pendingVerification) {
            if (status.length > 0 && message.length > 0) {
                return <button className='approved-button' onClick={() => setPendingVerification(true)}>Verify & Submit Response</button>
            } else {
                return <button className='denied-button'>Verify & Submit Response</button>
            }
        }

        // Verification
        return (
            <div className='response-buttons'>
                <div className='response-buttons'>
                    <div>
                        <button className='denied-button' onClick={() => setPendingVerification(false)}>Go Back</button>
                    </div>
                    <div>
                        <button className='approved-button' onClick={onResponseSubmit}>Submit Response</button>
                    </div>

                </div>
            </div>
        )
    }

    const renderAmountBox = () => {
        if (status === 'Approved') {
            return (
                <div className='amount-box'>
                    <label>
                        Amount of Assistance Provided (Whole Number): {amount.length !== 0 ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                        <InputText value={amount} onChange={(event) => setAmount(event.target.value)} />
                    </label>
                </div>
            )
        }
    }

    const renderApplicationResponse = () => {
        if (!pendingVerification) {
            return (
                <>
                    <div className='application-edit-section'>
                        <h1 className='section-header header'>Respond to Application</h1>
                        <p>Please remember you are typing the body of the email.</p>
                        <p>You do not need to greet the person, nor write who the email is from.</p>
                        <h2 className='header'>Emailing:</h2>
                        <p>{application.email}</p>
                        <InputTextarea autoResize={true} rows={5} cols={30}
                            id="assistanceDetails" value={message} onChange={onMessageInput} />
                        <h2 className='header'>Choose Status</h2>
                        <div className='status-list'>
                            <div>
                                <button className={status === "Denied" ? 'denied-button not-approved' : 'denied-button'}
                                    onClick={() => { setStatus("Denied") }}>Denied</button>
                            </div>

                            <div>
                                <button className={status === "Approved" ? 'approved-button approved' : 'approved-button'}
                                    onClick={() => { setStatus("Approved") }}>Approved</button>
                            </div>
                        </div>

                        {renderAmountBox()}

                        <h2 className='header'>Verify & Submit</h2>
                        {renderResponseButton()}


                    </div>
                </>
            )
        } else {
            return (
                <>
                    {renderKeyConfirm()}
                </>
            )
        }
    }

    // Attachments

    const renderAttachments = () => {
        if (attachments.length === 0)
            return (
                <ul className='attachments-container'>
                    {application.attachments.map((attachment, index) => {
                        return (
                            <li key={index} className='attachment-download'>
                                <AttachmentDownload name={attachment.name} size={attachment.size}
                                    link={attachment.data} type={attachment.contentType}
                                />
                            </li>
                        )
                    })}
                </ul>
            )

        return (
            <div>
                <div>
                    <h1 className='header'>Current Attachments</h1>
                    <ul className='attachments-container'>
                        {application.attachments.map((attachment, index) => {
                            return (
                                <li key={index} className='attachment-download'>
                                    <AttachmentDownload name={attachment.name} size={attachment.size}
                                        link={attachment.data} type={attachment.contentType}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div>
                    <h1 className='header'>New Attachments</h1>
                    {attachments.map((attachment, index) => {
                        return (
                            <li key={index} className='attachment-download'>
                                <AttachmentContainer attachment={attachment}
                                    pending={false} onPendingDelete={onPendingDelete}
                                    onFileDelete={onFileDelete}
                                />
                            </li>
                        )
                    })}
                </div>
            </div>
        )
    }

    // User Info

    const renderUserNotes = () => {
        if (user.notes.length > 0) {
            return (
                <>
                    <h2 className='header'>Notes</h2>
                    <ul className='notes-list'>
                        {user.notes.map((item, index) => {
                            return (
                                <li key={index}>
                                    <h1 className='header'>Note {index + 1}</h1>
                                    <h2 className='header'>Added by {item.name} on {item.date}</h2>
                                    <p>{item.body}</p>
                                </li>
                            )
                        })}
                    </ul>
                </>
            )
        }

        return <h2 className='header'>No Notes to Display</h2>
    }


    const renderUserInfo = () => {
        if (application.applicantID === "Guest") {
            return (
                <div className='user-info'>
                    <h3 className='header'>This user submitted their application as a guest.</h3>
                </div>
            )
        }

        if (!user) {
            return <button onClick={getUserInfo} className='approved-button user-info-button'>Get User Info</button>
        }

        if (user) {
            return (
                <>
                    <div className='review-section'>
                        <div className='review-items'>
                            <div className="review-item">
                                <h3>Name:</h3>
                                <p>{user.firstName} {user.lastName}</p>
                            </div>

                            <div className="review-item">
                                <h3>Email:</h3>
                                <p>{user.email}</p>
                            </div>

                            <div className="review-item">
                                <h3>Date Registered:</h3>
                                <p>{user.dateRegistered}</p>
                            </div>

                            <div className="review-item">
                                <h3>Applications Sent:</h3>
                                <p>{user.applicationTokens.length}</p>
                            </div>

                            <div className="review-item">
                                <h3>Assistance Recieved:</h3>
                                <p>{user.assistanceRecieved}</p>
                            </div>

                            <div className="review-item">
                                <h3>Signed Up for Newsletter?</h3>
                                <p>{user.newsletter ? "Yes" : "No"}</p>
                            </div>
                        </div>
                        {renderUserNotes()}
                    </div>
                </>
            )
        } else {
            return (
                <div className='user-info'>
                    <h1 className='header'>Unable to Display</h1>
                    <h2 className='header'>Error accessing user information from the database.</h2>
                </div>
            )

        }
    }

    const renderEmailButton = () => {
        // Main Response
        if (!pendingVerification) {
            if (email.length > 0) {
                return <button className='approved-button' onClick={() => setPendingVerification(true)}>Verify & Send Email</button>
            } else {
                return <button className='denied-button'>Verify & Send Email</button>
            }
        }

        // Verification
        return (
            <div className='response-buttons'>
                <div className='response-buttons'>
                    <div>
                        <button className='denied-button' onClick={() => setPendingVerification(false)}>Go Back</button>
                    </div>
                    <div>
                        <button className='approved-button' onClick={onSubmitEmail}>Send Email</button>
                    </div>

                </div>
            </div>
        )
    }

    // Email Renders

    const renderEmailKeyConfirm = () => {
        if (pendingVerification) {
            if (!emailSubmitted) {
                return (
                    <>
                        <>
                            <div className='pending-verification'>
                                <h1 className='header'>Verify Information</h1>
                                <h1 className='header'>Email Preview:</h1>
                                <EmailTemplate message={email}
                                    token={application.token} firstName={application.firstName} />
                                <div className='response-password-input-container'>
                                    <label>
                                        Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                        <Password type="password" name="key" value={key} onChange={(event) => setKey(event.target.value)} toggleMask={true} />
                                    </label>
                                </div>
                                {renderEmailButton()}
                            </div>
                        </>
                    </>
                )
            } else {
                return (
                    <>
                        <div className='response-submitted'>
                            <h1 className='header'>Email Sent!</h1>
                            <h2>The Applicant has been sent your email.</h2>
                        </div>
                    </>
                )
            }
        }
    }

    const renderEmailForm = () => {
        if (!pendingVerification) {
            return (
                <>
                    <div className='application-edit-section'>
                        <h1 className='header'>Email Applicant</h1>
                        <p>Please remember you are typing the body of the email.</p>
                        <p>You do not need to greet the person, nor write who the email is from.</p>
                        <h2 className='header'>Emailing:</h2>
                        <p>{application.email}</p>

                        <h2 className='header'>Write Your Message Below</h2>
                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="assistanceDetails" value={email} onChange={onEmailInput} />
                        </div>

                        {renderEmailButton()}
                    </div>
                </>
            )
        } else {
            return (
                <>
                    {renderEmailKeyConfirm()}
                </>
            )
        }
    }

    const renderAttachmentsForm = () => {
        if (!attachmentsSubmitted) {
            return (
                <>
                    <div className="application-section">
                        <h1 className="header">Add Attachments</h1>
                        <p>Add any required attachments below.</p>
                        {renderAttachments()}
                        <div className="attachment-button-row">

                            <label onChange={onFileUpload} htmlFor="formId">
                                <input name="" type="file" id="formId" hidden />
                                <div className="attachment-button approved-button">
                                    Add File
                                </div>
                            </label>

                            {attachmentError ? <h2>Error Submitting, Try Again or Contact Support</h2> : ""}
                        </div>

                        <button className={
                            attachments.length > 0 ? 'attachment-submit-button approved-button'
                                : 'attachment-submit-button denied-button'
                        } onClick={onSubmitAttachments}>Submit Attachments</button>
                    </div>
                </>
            )
        }

        return (
            <div className="application-section">
                <h1 className='header'>Attachments Submitted!</h1>
                <p>Attachments have successfully been submitted.</p>
            </div>

        )

    }

    const renderEditSection = () => {
        switch (showing) {
            // Nothing Showing
            case nothing:
                return "";

            // Notes Showing
            case notesShowing:
                return (
                    <>
                        <NotesDisplay notes={application.notes} userNotes={false} applicationNotes={true}
                            onNotesUpdate={onNotesUpdate} token={application.token} />
                    </>
                )
            // User Info Showing
            case infoShowing:
                return (
                    <>
                        {renderApplicationReview()}
                    </>
                )

            // Email showing
            case emailShowing:
                return (
                    <>
                        {renderEmailForm()}

                    </>
                )

            // Add Attachments Showing
            case attachmentsShowing:
                return (
                    <>
                        {renderAttachmentsForm()}
                    </>
                )

            // Respond to Application Showing
            case respondShowing:
                return (
                    <>
                        {renderApplicationResponse()}
                    </>
                )
        }
    }

    const renderApplicationHeader = () => {
        if (width > 780) {
            return (
                <div className='application-header'>
                    <h1 className='header'>Viewing {application.status} Application | {application.token} | ${application.amount} | Submitted {application.issuedOn}</h1>
                </div>
            )
        }

        return (
            <div className='application-header'>
                <h1 className='header'>Viewing {application.status} Application</h1>
            </div>
        )
    }

    const renderNav = () => {
        if (width <= 1200) {
            return (
                <>
                    <div className='mobile-application-edit-control-container'>
                        <div className='mobile-application-edit-control-row-1'>
                            <button className='approved-button' onClick={() => onNavbarClick(infoShowing)}>View All Info</button>
                            <button className='search-button' onClick={() => onNavbarClick(notesShowing)}>Notes ({application.notes.length})</button>
                            <button className='all-button' onClick={() => onNavbarClick(attachmentsShowing)}>Add Attachments</button>
                        </div>

                        <div className='mobile-application-edit-control-row-2'>
                            <button className='search-button' onClick={() => onNavbarClick(emailShowing)}>Email Applicant</button>
                            <button className='approved-button' onClick={() => onNavbarClick(respondShowing)}>Respond to Application</button>
                        </div>
                    </div>

                </>

            )
        }

        return (
            <div className='application-edit-nav'>
                <div className='application-edit-nav-row-1'>
                    <button className='approved-button' onClick={() => onNavbarClick(infoShowing)}>View All Info</button>
                    <button className='search-button' onClick={() => onNavbarClick(notesShowing)}>Notes ({application.notes.length})</button>
                    <button className='all-button' onClick={() => onNavbarClick(attachmentsShowing)}>Add Attachments</button>
                </div>

                <div className='application-edit-nav-row-2'>
                    <button className='search-button' onClick={() => onNavbarClick(emailShowing)}>Email Applicant</button>
                    <button className='approved-button' onClick={() => onNavbarClick(respondShowing)}>Respond to Application</button>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='application-edit'>
                <div className='back-button'>
                    <button className='denied-button' onClick={() => props.setEditing(false)}><i class="fa-solid fa-left-long"></i> Go Back</button>
                </div>
                {renderApplicationHeader()}
                <div className='application-control'>
                    {renderNav()}
                </div>
                {renderEditSection()}
            </div>
        </>
    )
}

export default EmployeeApplicationEdit;