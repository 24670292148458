import React, { useState, useLayoutEffect } from "react";
import { Fade } from "react-reveal";
import axios from "axios";

// Custom Styles

import "./HomeEventsStyles.css";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

function useFetchData() {
    axios.defaults.withCredentials = true;
    const [loading, setLoading] = useState(true);
    const [eventsData, setEventsData] = useState(null);

    React.useEffect(() => {
        if (!eventsData) {
            setLoading(true);
            axios.get('/api/upcoming-events', { withCredentials: true })
                .then((res) => {
                    if (res.data.status) {
                        setEventsData(res.data.events);
                    } else {
                        setEventsData(null);
                    }

                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                })
        }

    }, []);
    return { loading, eventsData };
}

export default function HomeEvents() {
    const { loading, eventsData } = useFetchData();
    const [width, height] = useWindowSize();

    if (loading || eventsData === null) {
        return (
            <>
                <div className="events-container">
                    <h1 className="header">Events That Warm the Heart</h1>

                    <div className="events-items">

                        <div className="events-item">
                            <Fade>
                                <div className="events-info events-text-container">
                                    <h2 className="header">Interested in Attending?</h2>
                                    <div className="events-text">
                                        <p>We'd love to have you.</p>
                                        <p>Hearts Unite hosts multiple in person events each year that are fun for the whole family.</p>
                                    </div>
                                    <form action="/events">
                                        <button action="submit" className="approved-button">Click Here to Find Out More</button>
                                    </form>
                                </div>
                            </Fade>

                        </div>

                        <div className="events-item">

                            <img src="/images/HUGS_events.png" alt="person painting heart" className="events-image" />

                        </div>
                    </div >
                </div >

            </>
        )
    }

    const renderEventsHeader = () => {
        if (width > 480) {
            return (
                <h2 className="header">We Have Events Coming Up</h2>
            )
        }

        return (
            <h2 className="header">Upcoming Events</h2>
        )
    }

    return (
        <>
            <div className="events-container">
                <h1 className="header">Events That Warm the Heart</h1>
                <div className="events-items">
                    <div className="events-item">
                        <Fade>
                            <div className="events-info events-text-container">
                                {renderEventsHeader()}
                                <ul className="event-names-container">
                                    {eventsData.map((event, index) => {
                                        return (
                                            <li>
                                                <h3 className="header">{event.date} - {event.name}</h3>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <h2 className="header">Interested in Attending?</h2>
                                <form action="/events">
                                    <button action="submit" className="approved-button">Click Here to Find Out More</button>
                                </form>
                            </div>
                        </Fade>
                    </div>

                    <div className="events-item">
                        <img src="/images/HUGS_events.png" alt="person painting heart" className="events-image" />
                    </div>
                </div>
            </div>

        </>
    )


}